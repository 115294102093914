.root {
  background-color: #202020 !important;
  border-radius: 5px;
  padding: 15px;
  z-index: 1000;

  &::before {
    background-color: var(--mantine-color-white) !important;
  }
}

.icon {
  background-color: #202020;
}

.description,
.title {
  color: var(--mantine-color-white) !important;
  letter-spacing: 0.37px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.description {
  font-size: 14px;
  line-height: 18px;
}

.closeButton {
  color: var(--mantine-color-white) !important;
}
.closeButton:hover > svg {
  color: black;
}

.myIcon {
  background: #6fb0f1;
  border-radius: 50px;
  fill: #000;
}

.myIconError {
  background: #e4032e;
  border-radius: 50px;
  fill: #fff;
}
